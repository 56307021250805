var exports = {};

function getParamSize(keySize) {
  var result = (keySize / 8 | 0) + (keySize % 8 === 0 ? 0 : 1);
  return result;
}

var paramBytesForAlg = {
  ES256: getParamSize(256),
  ES384: getParamSize(384),
  ES512: getParamSize(521)
};

function getParamBytesForAlg(alg) {
  var paramBytes = paramBytesForAlg[alg];

  if (paramBytes) {
    return paramBytes;
  }

  throw new Error("Unknown algorithm \"" + alg + "\"");
}

exports = getParamBytesForAlg;
export default exports;